import * as React from "react";
import Layout from "../components/common/Layout";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import SectionHeader from "../components/common/SectionHeader";
import DiagonalImage from "../images/diagonal-header.png";

const Section = styled.div`
  margin-top: 41px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  min-height: 200px;
`;
const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 2.5rem;
  list-style: none;
  font-family: "Lato";
  background: white;

  position: relative;
  margin-right: 60px;
  margin-left: 60px;
  padding-top: 42px;
  padding-left: 50px;
  padding-right: 50px;
  top: -85px;
  p {
    margin-bottom: 0;
  }

  header {
    margin-bottom: 10px;
  }
`;

const DiagonalHeader = styled.div``;

const Post = styled.li`
  min-width: 300px;
  a {
    text-decoration: none;
  }

  h2 {
    margin-top: 0;
    font-family: "Palestina";
    color: #00aeef;
    font-weight: normal;
    font-size: 32px;
  }

  img {
    width: 100%;
  }
`;

// markup
const BlogPage = ({ data }: { data: any }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout pageTitle="Blog">
      <SectionHeader text={"BLOG"} />
      <Section>
        <img
          style={{ width: "100%", minHeight: "100px" }}
          src={DiagonalImage}
          alt={"hi"}
        />
        {posts.length == 0 && <div>Blog posts coming soon</div>}
        <StyledList>
          {posts.map((post: any) => {
            const title = post.frontmatter.title || post.fields.slug;
            const image = require("../images/" +
              post.frontmatter.previewImage).default;

            return (
              <Post key={post.fields.slug}>
                <header>
                  <Link to={post.fields.slug} itemProp="url">
                    {post.frontmatter.previewImage && (
                      <img src={image} style={{ maxWidth: "400px" }} />
                    )}
                    <h2>{title}</h2>
                  </Link>
                  <small>{post.frontmatter.date}</small>
                </header>
              </Post>
            );
          })}
        </StyledList>
      </Section>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          previewImage
        }
      }
    }
  }
`;
